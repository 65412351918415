import facebookEmptyPostIcon from '@cohort/merchants/apps/facebook/triggers/facebookEmptyPostIcon.svg';
import FacebookPostCard from '@cohort/merchants/apps/facebook/triggers/FacebookPostCard';
import Button from '@cohort/merchants/components/buttons/Button';
import {
  EmptyState,
  EmptyStateContainer,
  EmptyStateTitle,
} from '@cohort/merchants/components/EmptyState';
import {RadioCards, RadioCardTopRightCheck} from '@cohort/merchants/components/form/RadioCards';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetTitle,
} from '@cohort/merchants/components/modals/Sheet';
import type {StepTriggerFormType} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/challenge/utils';
import type {FacebookPost} from '@cohort/shared/apps/facebook/common';
import {zodResolver} from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import type {UseFormReturn} from 'react-hook-form';
import {useController, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';
import {z} from 'zod';

const SelectPostFormSchema = z.object({
  postId: z.string().nullable(),
});
type SelectPostFormValues = z.infer<typeof SelectPostFormSchema>;

type SelectPostSheetProps = {
  posts: FacebookPost[];
  formReturn: UseFormReturn<StepTriggerFormType>;
  onClose: () => void;
};
const SelectPostSheet: React.FC<SelectPostSheetProps> = ({posts, formReturn, onClose}) => {
  const {setValue, control} = formReturn;
  const {field: triggerPostIdField} = useController({
    name: 'triggerIntegrationConfig.postId',
    control,
  });
  const {t} = useTranslation('app-facebook', {
    keyPrefix: 'components.selectPostSheet',
  });

  const {register: selectPostRegister, control: selectPostControl} = useForm<SelectPostFormValues>({
    resolver: zodResolver(SelectPostFormSchema),
    defaultValues: {
      postId: triggerPostIdField.value ?? null,
    },
  });
  const {field: postIdField} = useController({control: selectPostControl, name: 'postId'});

  const onSubmit = (): void => {
    if (postIdField.value !== null) {
      setValue('triggerIntegrationConfig.postId', postIdField.value);
      setValue(
        'triggerIntegrationConfig.postPermalink',
        posts.find(post => post.id === postIdField.value)?.permalinkUrl
      );
    }
    onClose();
  };

  const postsByMonth = posts.reduce((groups, post) => {
    const monthYear = dayjs(post.createdTime).format('MMMM YYYY');
    if (!groups.has(monthYear)) {
      groups.set(monthYear, []);
    }
    groups.get(monthYear)?.push(post);
    return groups;
  }, new Map<string, FacebookPost[]>());

  const postsContent = match(postsByMonth.size)
    .with(0, () => (
      <div className="flex h-full flex-col justify-center">
        <EmptyStateContainer>
          <EmptyState>
            <img src={facebookEmptyPostIcon} alt="Facebook empty state" />
            <EmptyStateTitle>{t('titleEmpty')}</EmptyStateTitle>
          </EmptyState>
        </EmptyStateContainer>
      </div>
    ))
    .otherwise(() =>
      Array.from(postsByMonth).map(([monthYear, posts]) => (
        <div key={monthYear} className="flex flex-col space-y-3">
          <p className="text-sm text-slate-700">{monthYear}</p>
          <RadioCards
            name="postId"
            direction="row"
            register={selectPostRegister}
            control={selectPostControl}
            className="grid grid-cols-1"
            options={posts.map(post => ({
              label: <FacebookPostCard post={post} isSelected={post.id === postIdField.value} />,
              value: post.id,
            }))}
            labelClassName="p-0"
            checkIconComponent={<RadioCardTopRightCheck />}
          />
        </div>
      ))
    );

  return (
    <Sheet open onOpenChange={onClose}>
      <SheetContent className="max-h-100vh flex flex-col gap-0 bg-white pt-6">
        <SheetTitle className="flex-shrink-0 px-6">{t('title')}</SheetTitle>
        <div className="no-scrollbar m-6 flex flex-grow flex-col gap-4 overflow-y-auto rounded-lg border border-slate-200 bg-slate-50 p-4">
          {postsContent}
        </div>
        <SheetFooter className="flex w-full !flex-row !justify-between border-t border-border px-6 py-4">
          <Button variant="secondary" onClick={onClose}>
            {t('buttonCancel')}
          </Button>
          <Button onClick={onSubmit} disabled={postIdField.value === null}>
            {t('buttonConfirm')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default SelectPostSheet;
